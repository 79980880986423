export function loadView (path, name) {
  return function (el, props) {
    return import(path + '.js')
      .then((module) => {
        if (name === undefined) {
          return module.default(el, props)
        } else {
          return module.default[name](el, props)
        }
      })
  }
}

export function logLoadView (path, name) {
  return function (el, props) {
    return import('daily_logs/views/' + path + '.js')
      .then((module) => {
        if (name === undefined) {
          return module.default(el, props)
        } else {
          return module.default[name](el, props)
        }
      })
  }
}

export function logCalendarLoadView (path, name) {
  return function (el, props) {
    return import('daily_log_calendar/views/' + path + '.js')
      .then((module) => {
        if (name === undefined) {
          return module.default(el, props)
        } else {
          return module.default[name](el, props)
        }
      })
  }
}

export function tagLoadView (path, name) {
  return function (el, props) {
    return import('tags/views/' + path + '.js')
      .then((module) => {
        if (name === undefined) {
          return module.default(el, props)
        } else {
          return module.default[name](el, props)
        }
      })
  }
}

