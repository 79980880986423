// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import './application.css';
import '../utilities/ajax_csrf.js';

// Deps
import domready from 'domready'
import viewloader from 'viewloader'

// Components
import daily_log_views from '../daily_logs';
import daily_log_calendar_views from '../daily_log_calendar';
import tag_views from '../tags';

// Kick off
domready(() => {
  var all_views = Object.assign({}, daily_log_views, daily_log_calendar_views, tag_views);
  const manager = viewloader(all_views);
  // // Call the view functions
  manager.callViews();
})

